import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import pharmacyPicture from '../../assets/images/pharmacy.jpg'
import logo from '../../assets/images/Philab-logo.png'
import play from '../../assets/images/play.svg'
import './style.scss'

import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { resetPasswordRequest } from './actions'
import { Slugs } from '../../utils/constants'
import { useHandleError } from '../../Hooks/useHandleError'
import useToast from '../../Hooks/useToast'
import Footer from '../_Layout/Footer'

function ResetPasswordView() {
  const { showSuccess } = useToast()

  const [params, setParams] = useSearchParams()
  const [resetJWT, setResetJWT] = useState('')
  const { showError } = useToast()
  useEffect(() => {
    if (params.get('token')) {
      setResetJWT(params.get('token')!)
      setParams([])
    }
  }, [params, setParams])

  const navigate = useNavigate()

  const handleError = useHandleError()

  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (pwd !== confirmPwd) {
      showError('Les mots de passe ne correspondent pas.')
      return
    }
    resetPasswordRequest({ password: pwd, token: resetJWT })
      .then(() => {
        showSuccess('Votre mot de passe a été réinitialisé.')
        navigate(Slugs.LOGIN)
      })
      .catch(handleError)
  }

  const video = React.createRef<HTMLVideoElement>()
  const playVideo = useCallback(() => {
    video.current?.play()
    setPlaying(true)
  }, [])
  const [playing, setPlaying] = useState(false)

  return (
    <>
      <div className="h-screen">
        <div className="main-container-login grid grid-cols-1 md:grid-cols-2">
          <div className="form-container min-h-[80vh] md:min-h-screen">
            <img src={logo} alt="logo" className="w-full max-w-sm mx-auto" />
            <div className="w-full px-3">
              <span className="title">
                <b>Réinitialisation de votre mot de passe</b>
              </span>
              <form
                className="login-form !w-full max-w-sm mx-auto"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  className="mb-1 login-form-label w-full"
                  label="Mot de passe"
                  controlId="password"
                >
                  <Form.Control
                    type="password"
                    placeholder="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    className="login-form-input w-full"
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-1 login-form-label w-full"
                  label="Confirmation du mot de passe"
                  controlId="password"
                >
                  <Form.Control
                    type="password"
                    placeholder="password"
                    onChange={(e) => setConfirmPwd(e.target.value)}
                    value={confirmPwd}
                    className="login-form-input w-full"
                    required
                  />
                </FloatingLabel>
                <div className="mt-3 mx-auto items-center flex flex-col">
                  <Button variant="primary text-white" size="lg" type="submit">
                    Réinitialiser votre mot de passe
                  </Button>
                  <a
                    href={Slugs.LOGIN}
                    className="text-sm mt-2 block text-center"
                  >
                    Retour à la connexion
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="max-md:py-4 img-container relative flex justify-center items-center font-sans">
            <img
              src={pharmacyPicture}
              alt="pharmacy"
              className="absolute w-full h-full inset-0 max-md:hidden"
            />
            <div className="absolute w-full bottom-0 h-16 from-transparent to-white bg-gradient-to-b" />
            <div className="w-[calc(100%-30px)] max-w-xl bg-white/30 backdrop-blur-sm rounded shadow text-neutral-700">
              <div className="text-center font-semibold text-xl py-1">
                Comment ça marche ?
              </div>
              <div className="relative aspect-video">
                <video
                  ref={video}
                  src="/videos/videoPhiLab.mp4"
                  className="rounded"
                  controls
                  preload="auto"
                />
                <div
                  onClick={playVideo}
                  className={`absolute w-full h-full inset-0 bg-white rounded cursor-pointer ${
                    playing ? 'opacity-0 pointer-events-none' : 'opacity-100'
                  } transition-opacity duration-500`}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="w-full h-full object-contain absolute inset-0"
                  />
                  <div className="grid place-items-center w-full h-full absolute inset-0 hover:backdrop-blur-none backdrop-blur-sm transition-all duration-200 group">
                    <img
                      src={play}
                      alt="Play"
                      className="w-16 h-16 rounded-full group-hover:scale-110 transition-transform duration-200"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default ResetPasswordView
