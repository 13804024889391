import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Card, Modal } from 'react-bootstrap'
import Counter from './Counter'
import './style.scss'
import { Product } from '../../Types/Product'
import { shrinkText } from '../../utils/helpers'
import { NavLink } from 'react-router-dom'
import productPlaceholder from '../../assets/images/product_placeholder.png'
import { addToBasket } from '../../Views/ProductView/actions'
import { BasketActionType, BasketContext } from '../../Contexts/basket/context'
import useToken from '../../Hooks/useToken'
import { LoadingComponent } from '../Loading'
import { Slugs } from '../../utils/constants'
import useAuth from '../../Hooks/useAuth'
import { Roles } from '../../Types/Role'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { toggleVisibility } from './actions'
import { useHandleError } from '../../Hooks/useHandleError'
import GapCounter from './GapCounter'
import {
  BusinessCondition,
  BusinessConditionStep,
  BusinessConditionType,
  ProductBusinessConditionStep,
} from '../../Types/BusinessCondition'
import useToast from '../../Hooks/useToast'
import { removeBasketProduct } from '../../Views/CartView/actions'
import { updatePrice as updatePriceBack } from './actions'

const BigPrice = ({
  price,
  isDisabled,
}: {
  price: string
  isDisabled: boolean
}) => {
  let priceString = price.toString()
  // split float number into two parts
  let priceSplit = priceString.split('.')

  return (
    <div className={isDisabled ? 'text-secondary' : 'text-primary'}>
      {priceSplit[0] && (
        <span>
          {priceSplit[0]}
          <sup className="fs-5">€</sup>
        </span>
      )}
      {priceSplit[1] && (
        <small className="fs-6" style={{ marginLeft: '-0.5rem' }}>
          ,{priceSplit[1]}
        </small>
      )}
    </div>
  )
}

const ItemCard = (props: {
  product: Product
  stock?: number
  // sales: (ProductBusinessConditionStep | BusinessConditionStep)[]
  handleRefresh?: () => void
  hideParams?: boolean
  noLink?: boolean
}) => {
  const [count, setCount] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  const { loginUser } = useToken()
  const { user } = useAuth()
  const { dispatch, state } = React.useContext(BasketContext)
  const handleError = useHandleError()
  const { showError } = useToast()

  const handleToggleVisibility = () => {
    if (!+props.product.price) openModal()
    else
      toggleVisibility(loginUser, props.product)
        .then(props.handleRefresh)
        .catch(handleError)
  }

  useEffect(() => {
    if (state.basket) {
      const amountInBasket =
        state.basket.basketProducts.find(
          (bp) => bp.product.id === props.product.id
        )?.amount || 0
      setCount(amountInBasket)
    }
  }, [])

  const basketAmount = useMemo(
    () =>
      state.basket?.basketProducts.reduce(
        (val, prod) =>
          prod.product.id == props.product.id ? val + prod.amount : val,
        0
      ) ?? 0,
    [props.product.id, state.basket]
  )

  // Manage business conditions for reducing price

  const [reducePrice, setReducePrice] = useState<BusinessConditionStep[]>([])
  const [freeUnit, setFreeUnit] = useState<number | undefined>()
  useEffect(() => {
    if (props.product.businessConditions) {
      let reducePrice: BusinessConditionStep[] = []
      let freeUnit: number | undefined
      const sales = props.product.businessConditions
      sales.forEach((sale) => {
        switch (sale.type) {
          case BusinessConditionType.PRODUCT_AMOUNT:
            sale.steps.forEach((step) => reducePrice.push(step))
            break
          case BusinessConditionType.FREE_UNIT:
            freeUnit = sale.freeUnitCap
            setFreeUnit(freeUnit)
        }
      })
      // sort by minimum order amount
      reducePrice.sort((a, b) => a.minimumOrderAmount - b.minimumOrderAmount)
      setReducePrice(reducePrice)
    }
  }, [props.product])

  // const sales = useMemo(
  //   () =>
  //     props.product.businessConditions
  //       .filter(
  //         (sale) => !sale.products || sale.products.includes(props.product.id)
  //       )
  //       .sort((a, b) => a.minimumOrderAmount - b.minimumOrderAmount)
  //       .reduce(
  //         (list, next) =>
  //           !list.length ||
  //           next.discountRate > list[list.length - 1].discountRate
  //             ? [...list, next]
  //             : list,
  //         [] as BusinessConditionStep[]
  //       ),
  //   [props.product.id]
  // )

  const currentRealSale = useMemo(
    () =>
      [...reducePrice]
        .reverse()
        .find((sale) => basketAmount >= sale.minimumOrderAmount),
    [reducePrice, basketAmount]
  )
  const currentSale = useMemo(
    () =>
      [...reducePrice]
        .reverse()
        .find((sale) => count >= sale.minimumOrderAmount),
    [reducePrice, count]
  )
  const nextSale = useMemo(
    () => reducePrice.find((sale) => count < sale.minimumOrderAmount),
    [reducePrice, count]
  )

  const handleAddToCart = useCallback(() => {
    if (count % props.product.pas != 0) {
      showError(
        `Vous ne pouvez ajouter que des multiples de ${props.product.pas} pour ce produit.`
      )
      return
    }
    setIsLoading(true)
    if (count == 0 && basketAmount) {
      const basketProduct = state.basket?.basketProducts.find(
        (basketProduct) => basketProduct.product.id == props.product.id
      )
      if (basketProduct)
        removeBasketProduct(basketProduct.id, loginUser)
          .then((basket) =>
            dispatch({
              type: BasketActionType.GET_BASKET,
              payload: basket,
            })
          )
          .then(() => setIsLoading(false))
    } else if (count != 0)
      addToBasket(props.product, loginUser, count).then((basket) => {
        dispatch({ type: BasketActionType.GET_BASKET, payload: basket })
        setIsLoading(false)
      })
  }, [
    props.product.id,
    props.product.pas,
    loginUser,
    count,
    !!basketAmount,
    state.basket?.basketProducts,
  ])

  const [showPriceChangeModal, setShowPriceChangeModal] = useState(false)
  const [priceUpdateLoading, setPriceUpdateLoading] = useState(false)

  const [price, setPrice] = useState(props.product.price)
  useEffect(() => setPrice(props.product.price), [props.product.price])

  const updatePrice = useCallback(() => {
    if (price && !isNaN(+price) && +price) {
      props.product.price = price
      setPriceUpdateLoading(true)
      updatePriceBack(loginUser, props.product)
        .then(() => setShowPriceChangeModal(false))
        .then(props.handleRefresh)
        .finally(() => setPriceUpdateLoading(false))
    }
  }, [price, props.product, props.handleRefresh])

  const openModal = useCallback(() => {
    setShowPriceChangeModal(true)
    setPrice(props.product.price)
  }, [props.product.price])

  return (
    <Card className="text-start item_card">
      <NavLink to={props.noLink ? '' : `${Slugs.PRODUCT}/${props.product.id}`}>
        <Card.Img
          variant="top"
          src={props.product.photo_url || productPlaceholder}
          className="aspect-square max-h-36 object-contain"
          style={{ borderBottom: '1px solid #888' }}
        />
      </NavLink>
      <Card.Body className={!props.product.isVisible ? 'product-hidden' : ''}>
        <NavLink
          to={props.noLink ? '' : `${Slugs.PRODUCT}/${props.product.id}`}
        >
          <Card.Subtitle className="card-product-title">
            {shrinkText(props.product.name, 60)}{' '}
            {props.product.prescriptionNeeded ? (
              <span className="hover-text" data-hover="Ordonnance requise">
                <Badge bg="info">Ord.</Badge>
              </span>
            ) : null}
            {props.product.ean13 && (
              <span className="text-muted text-xs">
                <br />
                aen13 : {props.product.ean13}
              </span>
            )}
          </Card.Subtitle>
        </NavLink>
        <Card.Title className="fs-3 text-nowrap text-primary d-flex align-items-end justify-content-around pt-3">
          <div>
            <BigPrice
              price={props.product.price || '0'}
              isDisabled={!props.product.isVisible}
            />
          </div>
          <div>
            <i className="text-muted fs-6 fw-normal text-truncate text-nowrap ms-1">
              {props.product.vatRate}% TVA
            </i>
          </div>
        </Card.Title>
        {!props.hideParams &&
          (user.role === Roles.pharma ? (
            <>
              <div className="d-flex">
                <Counter
                  count={count}
                  setCount={setCount}
                  gap={props.product.pas}
                />
                <Button
                  onClick={handleAddToCart}
                  variant="primary"
                  size="sm"
                  className="ml-1"
                >
                  {isLoading ? (
                    <LoadingComponent size={15} />
                  ) : basketAmount ? (
                    count ? (
                      'Modifier'
                    ) : (
                      'Retirer'
                    )
                  ) : (
                    'Ajouter'
                  )}
                </Button>
              </div>
              <div className="-mb-2 mt-1">
                {currentSale && (
                  <div className="text-green-500 text-xs mt-1 -mb-1">
                    Vous{' '}
                    {currentSale != currentRealSale ? 'obtiendrez' : 'obtenez'}{' '}
                    <b>-{currentSale.discountRate}%</b>
                  </div>
                )}
                {nextSale && (
                  <span className="text-green-400 text-sm">
                    Encore <b>{nextSale.minimumOrderAmount - count}</b> articles
                    pour <b>-{nextSale.discountRate}%</b>
                  </span>
                )}

                {freeUnit && reducePrice.length > 0 && (
                  <>
                    <hr />
                    {/* <br /> */}
                  </>
                )}
                {freeUnit && (
                  <span className="text-black-400 text-sm">
                    Une unitée gratuite pour <b>{freeUnit}</b> achetée
                    {freeUnit > 1 && 's'}
                  </span>
                )}
              </div>
              {props.stock && (
                <div className="mt-3 font-bold">
                  Votre stock : {props.stock}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex justify-between">
                <div>
                  <div>Pas :</div>
                  <GapCounter product={props.product} />
                </div>
                <div className="ml-1">
                  <div className="invisible">Pas</div>
                  <Button
                    onClick={handleToggleVisibility}
                    variant="outline-secondary"
                    size="sm"
                  >
                    {props.product.isVisible ? <FiEye /> : <FiEyeOff />}
                  </Button>
                </div>
              </div>
              <Button
                onClick={openModal}
                variant="primary"
                className="w-full mt-2.5 py-1"
              >
                Modifier le prix
              </Button>
            </>
          ))}
      </Card.Body>
      <Modal
        show={showPriceChangeModal}
        onHide={() => setShowPriceChangeModal(false)}
        contentClassName="p-3"
      >
        <div className="font-semibold text-lg mb-2">{props.product.name}</div>
        <div className="text-lg">
          <input
            type="number"
            className="border-2 px-2 py-1 rounded-md border-green-600"
            value={price}
            onChange={(event) => setPrice(event.target.value)}
          />
          <button
            disabled={priceUpdateLoading}
            onClick={updatePrice}
            className={`bg-green-500 hover:bg-green-600 transition-colors rounded text-white px-2 py-1 ml-3 ${
              priceUpdateLoading && 'opacity-70'
            }`}
          >
            Sauvegarder
          </button>
        </div>
      </Modal>
    </Card>
  )
}

export default ItemCard
