import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAuth from '../../Hooks/useAuth'
import pharmacyPicture from '../../assets/images/pharmacy.jpg'
import logo from '../../assets/images/Philab-logo.png'
import play from '../../assets/images/play.svg'
import './style.scss'

import useToken from '../../Hooks/useToken'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { contact, forgotPasswordRequest, getUser, login } from './actions'
import { Slugs } from '../../utils/constants'
import { useHandleError } from '../../Hooks/useHandleError'
import useToast from '../../Hooks/useToast'
import Footer from '../_Layout/Footer'
import ReCAPTCHA from 'react-google-recaptcha'

function LoginView() {
  const [registerModalOpen, setRegisterModalOpen] = useState<boolean>(false)

  const { setUser } = useAuth()
  const { setToken } = useToken()
  const { showSuccess } = useToast()

  const [params, setParams] = useSearchParams()
  const [forgotPassword, setForgotPassword] = useState(false)
  useEffect(() => {
    if (params.get('v') && params.get('v') === 'success') {
      showSuccess('Le compte a bien été vérifié. Vous pouvez vous connecter.')
      setParams([])
    }
  }, [])

  const navigate = useNavigate()

  const handleError = useHandleError()

  const onModalHide = () => {
    setRegisterModalOpen(false)
  }

  const [contactOpen, setContactOpen] = useState<boolean>(false)
  const onContactHide = useCallback(() => setContactOpen(false), [])

  const [userName, setUserName] = useState('')
  const [pwd, setPwd] = useState('')

  const [modalNom, setModalNom] = useState('')
  const [modalPrenom, setModalPrenom] = useState('')
  const [modalEmail, setModalEmail] = useState('')
  const [modalObject, setModalObject] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [loadingContact, setLoadingContact] = useState(false)
  const [errorContact, setErrorContact] = useState(false)

  const recaptchaRef = React.createRef<ReCAPTCHA>()
  const handleContactSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoadingContact(true)

    const token = (await recaptchaRef.current!.executeAsync()) as string

    contact({
      nom: modalNom,
      prenom: modalPrenom,
      email: modalEmail,
      object: modalObject,
      message: modalMessage,
      recaptcha: token,
    })
      .then(({ ok }) => {
        setErrorContact(!ok)
        if (ok) {
          setContactOpen(false)
          setModalNom('')
          setModalPrenom('')
          setModalEmail('')
          setModalObject('')
          setModalMessage('')
          showSuccess('Votre demande a bien été envoyée.')
        }
      })
      .catch(() => setErrorContact(true))
      .finally(() => setLoadingContact(false))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (forgotPassword) {
      forgotPasswordRequest({ email: userName }).then(() => {
        setForgotPassword(false)
        showSuccess('Un mail de réinitialisation a été envoyé à votre adresse.')
      })
    } else {
      login({ email: userName, password: pwd })
        .then(({ access_token }) => {
          setToken({ token: access_token })
          getUser(access_token)
            .then((user) => {
              setUser(user)
              setUserName('')
              setPwd('')
              navigate(Slugs.HOME)
            })
            .catch(handleError)
        })
        .catch((error) => {
          console.log(error)
          ;(window as any).a = error
          handleError(error)
        })
    }
  }
  const text = [
    [
      'Accessible 24h/24 & 7j/7',
      "Avec PhiLab, vous pouvez passer commande auprès de vos fournisseurs à toute heure, indépendamment des horaires d'ouvertures.",
    ],
    [
      'Dénichez de nouveaux produits',
      'Dans le riche catalogue produit de PhiLab, vous trouverez la perle rare à proposer à vos clients.',
    ],
    [
      '1 commande pour plusieurs fournisseurs',
      'Philab fonctionne comme une marketplace. Choisissez simplement les produits que vous souhaitez eu importe le fournisseur, et commandez !',
    ],
    [
      'Retrouvez vos conditions commerciales',
      'PhiLab recense toutes les conditions commerciales définies pour votre pharmacie ou votre groupement. Vous pourrez donc toujours profiter de vos tarifs préférentiels négociés.',
    ],
    [
      'La transparence nous tient à cœur',
      'Afin de vous garantir une transparence optimale, les frais de livraisons ainsi que les remises associées à chaque produit sont clairement affichés.',
    ],
  ]

  const video = React.createRef<HTMLVideoElement>()
  const playVideo = useCallback(() => {
    video.current?.play()
    setPlaying(true)
  }, [])
  const [playing, setPlaying] = useState(false)

  return (
    <>
      <div className="h-screen">
        <div className="main-container-login grid grid-cols-1 md:grid-cols-2">
          <div className="form-container min-h-[80vh] md:min-h-screen">
            <img src={logo} alt="logo" className="w-full max-w-sm mx-auto" />
            <div className="w-full px-3">
              <span className="title">
                {forgotPassword ? (
                  <b>Récupération de mot de passe</b>
                ) : (
                  <b>Connexion</b>
                )}
              </span>
              <form
                className="login-form !w-full max-w-sm mx-auto"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  className="mb-1 login-form-label w-full"
                  label="Email"
                  controlId="username"
                >
                  <Form.Control
                    type="text"
                    placeholder="votre email"
                    autoComplete="off"
                    className="login-form-input w-full"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                    required
                  />
                </FloatingLabel>
                {!forgotPassword && (
                  <FloatingLabel
                    className="mb-1 login-form-label w-full"
                    label="Mot de passe"
                    controlId="password"
                  >
                    <Form.Control
                      type="password"
                      placeholder="password"
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      className="login-form-input w-full"
                      required
                    />
                  </FloatingLabel>
                )}
                <div className="mt-3 mx-auto items-center flex flex-col">
                  <Button variant="primary text-white" size="lg" type="submit">
                    {forgotPassword
                      ? 'Envoyer un mail de réinitialisation'
                      : 'Se connecter'}
                  </Button>
                  <div
                    onClick={() => setForgotPassword(!forgotPassword)}
                    className="text-sm mt-2 block text-center"
                  >
                    {forgotPassword
                      ? 'Retour à la connexion'
                      : 'Mot de passe oublié ? Récupérez-le'}
                  </div>
                </div>
              </form>
              <p className="text">
                {'Pas de compte ? '}
                <span
                  className="link pointer text-primary"
                  onClick={() => setRegisterModalOpen(true)}
                >
                  Inscrivez-vous
                </span>
              </p>
            </div>
          </div>
          <div className="max-md:py-4 img-container relative flex justify-center items-center font-sans">
            <img
              src={pharmacyPicture}
              alt="pharmacy"
              className="absolute w-full h-full inset-0 max-md:hidden"
            />
            <div className="absolute w-full bottom-0 h-16 from-transparent to-white bg-gradient-to-b" />
            <div className="w-[calc(100%-30px)] max-w-xl bg-white/30 backdrop-blur-sm rounded shadow text-neutral-700">
              <div className="text-center font-semibold text-xl py-1">
                Comment ça marche ?
              </div>
              <div className="relative aspect-video">
                <video
                  ref={video}
                  src="/videos/videoPhiLab.mp4"
                  className="rounded"
                  controls
                  preload="auto"
                />
                <div
                  onClick={playVideo}
                  className={`absolute w-full h-full inset-0 bg-white rounded cursor-pointer ${
                    playing ? 'opacity-0 pointer-events-none' : 'opacity-100'
                  } transition-opacity duration-500`}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="w-full h-full object-contain absolute inset-0"
                  />
                  <div className="grid place-items-center w-full h-full absolute inset-0 hover:backdrop-blur-none backdrop-blur-sm transition-all duration-200 group">
                    <img
                      src={play}
                      alt="Play"
                      className="w-16 h-16 rounded-full group-hover:scale-110 transition-transform duration-200"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:col-span-2 px-5 font-sans mb-5 max-w-3xl mx-auto text-center">
            {text.map(([title, content], index) => (
              <div className={`mt-5`} key={index}>
                <div className="font-bold mb-2 text-lg">{title}</div>
                <div>{content}</div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={registerModalOpen} onHide={onModalHide}>
        <Modal.Header closeButton>
          <Modal.Title>Inscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <>
              <Button
                variant="primary"
                className="p-2 m-2 text-white"
                onClick={() => navigate(Slugs.REGISTER_DISPENSARY)}
              >
                Je suis un pharmacien
              </Button>
              <Button
                variant="primary"
                className="p-2 m-2 text-white"
                onClick={() => setContactOpen(true)}
              >
                Je suis un fournisseur
              </Button>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onModalHide}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={contactOpen} onHide={onContactHide}>
        <form className="login-form" onSubmit={handleContactSubmit}>
          {errorContact && (
            <div className="rounded mb-2 w-full px-3 py-1.5 bg-red-500 text-center text-white font-medium">
              Le message n'a pas pu être envoyé
            </div>
          )}
          <div className="grid grid-cols-2 gap-3">
            <FloatingLabel
              className="mb-1 login-form-label"
              label="Nom"
              controlId="name"
            >
              <Form.Control
                type="text"
                placeholder="votre nom"
                autoComplete="off"
                className="login-form-input"
                onChange={(e) => setModalNom(e.target.value)}
                value={modalNom}
                required
              />
            </FloatingLabel>
            <FloatingLabel
              className="mb-1 login-form-label"
              label="Prénom"
              controlId="prenom"
            >
              <Form.Control
                type="text"
                placeholder="votre prénom"
                autoComplete="off"
                className="login-form-input"
                onChange={(e) => setModalPrenom(e.target.value)}
                value={modalPrenom}
                required
              />
            </FloatingLabel>
          </div>
          <FloatingLabel
            className="mb-1 login-form-label"
            label="Email"
            controlId="username"
          >
            <Form.Control
              type="email"
              placeholder="votre email"
              autoComplete="off"
              className="login-form-input"
              onChange={(e) => setModalEmail(e.target.value)}
              value={modalEmail}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-1 mt-3 login-form-label"
            label="Object"
            controlId="object"
          >
            <Form.Control
              type="text"
              placeholder="votre objet"
              autoComplete="off"
              className="login-form-input"
              onChange={(e) => setModalObject(e.target.value)}
              value={modalObject}
              required
            />
          </FloatingLabel>
          <FloatingLabel
            className="mb-1 login-form-label"
            label="Votre message"
            controlId="message"
          >
            <Form.Control
              as="textarea"
              placeholder="votre contenu"
              autoComplete="off"
              className="login-form-input !h-52"
              onChange={(e) => setModalMessage(e.target.value)}
              value={modalMessage}
              required
            />
          </FloatingLabel>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdVozslAAAAAElChFP_kNK2NmLE_gl58Tz2BfLM"
          />
          ,
          <Button
            variant="primary text-white mt-3"
            size="lg"
            type="submit"
            disabled={loadingContact}
          >
            Envoyer
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default LoginView
