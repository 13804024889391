import { LoginUser, User } from '../../Types/User'
import { get, HTTPRequestError } from '../../Api/api'
import { Business } from '../../Types/Business'
import { Dispensary } from '../../Types/Dispensary'
import { Provider } from '../../Types/Provider'

const LOGIN_URL = '/api/auth/login'
const FORGOT_PASSWORD_URL = '/api/auth/forgot-password'
const GET_USER_URL = '/auth/profile'
const CONTACT_URL = '/api/contact/register'

export const getUser = async (token: string): Promise<User> => {
  return await get<User>(GET_USER_URL, { access_token: token })
}

export const forgotPasswordRequest = async (payload: {
  email: string
}): Promise<{ ok: boolean }> => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const res = await fetch(FORGOT_PASSWORD_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  })
  if (!res.ok) {
    const msg = await res.json()
    throw new HTTPRequestError(
      res.status,
      msg.message ?? msg.error ?? 'Une erreur est survenue'
    )
  }
  return await res.json()
}

export const login = async (payload: {
  email: string
  password: string
}): Promise<LoginUser> => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const res = await fetch(LOGIN_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  })

  if (!res.ok) {
    const msg = await res.json()

    throw new HTTPRequestError(
      res.status,
      msg.message ?? msg.error ?? 'Une erreur est survenue'
    )
  }

  return await res.json()
}
export const contact = async (payload: {
  nom: string
  prenom: string
  email: string
  object: string
  message: string
  recaptcha: string
}): Promise<{ ok: boolean }> => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const res = await fetch(CONTACT_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  })

  if (!res.ok) {
    try {
      throw new HTTPRequestError(res.status, await res.json())
    } catch {
      throw new HTTPRequestError(res.status, res.statusText)
    }
  }

  return await res.json()
}

export const getBusinessType = async (payload: {
  login: LoginUser
  business: Pick<Business, 'id'>
}) => {
  return get<Dispensary | Provider>(
    `/business/type/${payload.business.id}`,
    payload.login
  )
}
