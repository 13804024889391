export enum Slugs {
  HOME = '/home',
  SHOP = '/shop',
  INFO = '/info',
  PRODUCT = '/product',
  PROFILE = '/profil',
  LOGIN = '/login',
  REGISTER_DISPENSARY = '/inscription/pharmacien',
  REGISTER_PROVIDER = '/inscription/fournisseur',
  ORDERS_DISPENSARY = '/mes-commandes',
  STOCK = '/stock',
  RESET_PASSWORD = '/reset-password',
  AJOUTER_CATALOGUE = '/nouveau-catalogue',
  CART = '/panier',
  ORDERS_ADMIN = '/commandes-admin',
  ORDERS_PROVIDER = '/commandes',
  BUSINESS_CONDITIONS = '/commercial',
  BUSINESS_CONDITIONS_CREATE = '/commercial/create',
  BUSINESS_CONDITIONS_EDIT = '/commercial/edit',
  WHO_WE_ARE = '/qui-sommes-nous',
  CONTACT = '/contact',
  LEGAL_MENTIONS = '/mentions-legales',
  SALES_TERMS = '/cgv',
  CGU = '/cgu',
  CONDIFENCIALITY = '/politique-de-confidencialite',
  ACCOUNT_MANAGEMENT = '/comptes',
  DOWNLOAD_FACTURE = '/download',
  DISPENSERS = '/dispensers',
}
