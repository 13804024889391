import { HTTPRequestError } from '../../Api/api'

const RESET_PASSWORD_URL = '/api/auth/reset-password'

export const resetPasswordRequest = async (payload: {
  password: string
  token: string
}): Promise<{ ok: boolean }> => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const res = await fetch(RESET_PASSWORD_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  })
  if (!res.ok) {
    const msg = await res.json()
    throw new HTTPRequestError(
      res.status,
      msg.message ?? msg.error ?? 'Une erreur est survenue'
    )
  }
  return await res.json()
}
